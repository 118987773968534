<template>
  <div class="articlesadd-wrap">
    <div class="add-title">
      <router-link to="/" class="add-back">Home</router-link>
      Hi, post your awesome findings to others!
    </div>
    <div class="add-step">
      <ul>
        <li class="cur">1. Add a New Article</li>
        <li>2. Add Specific Questions</li>
        <li>3. Preview</li>
      </ul>
    </div>
    <div v-if="reviewStatus === 'pending'" class="review-message">
      Your article is under review. Please wait...
    </div>
    <div v-else class="add-box">
      <div class="add-item">
        <label class="add-label">1. Article Title:</label>
        <input type="text" name="title" placeholder="" id="title" class="add-input" v-model="Ans.name" />
      </div>
      <div class="add-item">
        <label class="add-label">2. Article Link(URL):</label>
        <input type="text" name="link" placeholder="" id="link" class="add-input" v-model="Ans.link" />
      </div>
      <div class="add-item">
        <label class="add-label">3. Article Overview:</label>
        <textarea class="add-textarea" id="overview" v-model="Ans.overview"></textarea>
      </div>
      <div class="add-item flex">
        <div class="w100">
          <label class="add-label">4. Article Source</label>
          <input type="text" name="source" placeholder="" id="source" class="add-input" v-model="Ans.source" />
        </div>
      </div>
      <div class="add-item flex">
        <div class="w50">
          <label class="add-label">5. Topic</label>
          <select name="topic" class="add-input" id="topic" v-model="Ans.topic">
            <option value="Technology & Science">Technology & Science</option>
            <option value="Education">Education</option>
            <option value="COVID-19">COVID-19</option>
            <option value="Health">Health</option>
            <option value="Society & Culture">Society & Culture</option>
            <option value="Environment">Environment</option>
            <option value="Business & Finance">Business & Finance</option>
            <option value="Entertainment & Arts">Entertainment & Arts</option>
            <option value="Politics">Politics</option>
          </select>
        </div>
        <div class="w50">
          <label class="add-label">6. Category</label>
          <select name="category" class="add-input" id="category" v-model="Ans.category">
            <option value="News">News</option>
            <option value="Advice">Advice</option>
            <option value="Analysis">Analysis</option>
            <option value="Interview">Interview</option>
            <option value="Discussion">Discussion</option>
            <option value="Chronicle">Chronicle</option>
            <option value="Video">Video</option>
          </select>
        </div>
      </div>
      <div class="add-item">
        <label class="add-label">7. Suggested reading time</label>
        <input type="number" name="time" placeholder="" id="time" class="add-input w135" v-model="Ans.readingTimes" />
        <span class="add-text">mins</span>
      </div>
    </div>
    <div class="add-btn">
      <div class="form-btn" @click="submitArticle" v-if="reviewStatus !== 'pending'">
        Submit
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { post } from "@/api"; // Import post method for API interaction

export default {
  name: "ArticleAdd",
  setup() {
    const uid = ref(-1);
    const uname = ref("");
    const Ans = ref({
      name: "",
      link: "",
      overview: "",
      source: "",
      topic: "",
      category: "",
      readingTimes: 0,
      id: -1,
    });
    const newId = ref(-1);
    const reviewStatus = ref(null); // To track the review status
    const router = useRouter();

    if (sessionStorage.getItem("newArticle")) {
      const articleData = JSON.parse(sessionStorage.getItem("newArticle"));
      console.log('Loaded newArticle from sessionStorage:', articleData);
      Object.assign(Ans.value, articleData); // Update Ans with article data
      newId.value = articleData.id; // Update newId with article ID
    }

    if (sessionStorage.getItem("token")) {
      post(`/user/logged`).then((res) => {
        if (res.res == 1) {
          uid.value = res.user.uid;
          uname.value = res.user.uname;
        } else {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem('Authorization');
        }
      });
    }

    function submitArticle() {
      if (!Ans.value.id) {
        Ans.value.id = newId.value;
      }

      let flag = true;
      if (reviewStatus.value === 'changing' || reviewStatus.value === 'modifyarticlelv3') {
        // If changing or modifying, all fields must be filled
        const requiredFields = ['name', 'link', 'overview', 'source', 'topic', 'category', 'readingTimes'];
        requiredFields.forEach((field) => {
          if (Ans.value[field] === "" || Ans.value[field] === null || Ans.value[field] === undefined) {
            flag = false;
          }
        });
      } else {
        // If adding new article, only URL must be filled
        if (Ans.value.link === "") {
          flag = false;
        }
      }

      if (flag) {
        const readingTimes = Ans.value.readingTimes !== "" ? parseInt(Ans.value.readingTimes, 10) : 0;

        // Define the endpoint based on reviewStatus
        let endpoint = '/article/add-article';
        if (reviewStatus.value === 'changing') {
          endpoint = '/article/change-article';
        } else if (reviewStatus.value === 'modifyarticlelv3') {
          endpoint = '/admin/modify-article';
        }

        const requestData = {
          moderator: uid.value,
          name: Ans.value.name,
          link: Ans.value.link,
          overview: Ans.value.overview,
          source: Ans.value.source,
          topic: Ans.value.topic,
          tags: Ans.value.topic,
          category: Ans.value.category,
          readingTimes: readingTimes,
          uid: uid.value,
          articlePath: "",
          likes: 0
        };
        console.log(requestData)

        // Add article ID only if reviewStatus is 'changing' or 'modifyarticlelv3'
        if (reviewStatus.value === 'changing' || reviewStatus.value === 'modifyarticlelv3') {
          requestData.id = newId.value;
        }
        sessionStorage.setItem("newArticle", JSON.stringify(Ans.value));
        if (reviewStatus.value !== 'modifyarticlelv3') {
          sessionStorage.setItem("reviewStatus", "pending");
          reviewStatus.value = "pending"; // Set review status to pending
        }

        // Send article data to backend for review
        post(endpoint, requestData).then(response => {
          if (response.data > 0 || response.res >0) {
            newId.value = response.data; 
            Ans.value.id = newId.value; // Save the article ID to Ans
            sessionStorage.setItem("newArticle", JSON.stringify(Ans.value)); // Save the article to sessionStorage
            sessionStorage.setItem("newId", newId.value); // Save the article ID to sessionStorage

            if (reviewStatus.value === 'modifyarticlelv3') {
              sessionStorage.setItem("reviewStatus", "approved");
              reviewStatus.value = "approved"; // Directly set the review status to approved
              router.push("/");
            }
          } else {
            ElMessage({
              message: response.data === "Article already exists"
                ? "Article already exists. Redirecting to the homepage."
                : "Failed to submit the article. Please try again.",
              type: "error",
            });
            if (response.data === "Article already exists") {
              setTimeout(() => {
                router.push("/");
              }, 2000);
            }
          }
        }).catch(error => {
          reviewStatus.value = null;
          ElMessage({
            message: "An error occurred while submitting the article. Please try again.",
            type: "error",
          });
        });
      } else {
        console.log(Ans.value)
        ElMessage({
          message: reviewStatus.value === 'changing' || reviewStatus.value === 'modifyarticlelv3'
            ? "Please fill all the fields."
            : "Please provide the article URL.",
          type: "warning",
        });
      }
    }

    function checkReviewStatus(articleId) {
      // Check the review status
      post('/admin/check-review-article', { articleId: articleId,moderator:uid.value }).then(res => {
        if (res.status === 'under_assessment') {
          reviewStatus.value = "pending"; // Set review status to pending
        } else {
          reviewStatus.value = res.status; // Set review status to the response status
        }
      }).catch(error => {
        ElMessage({
          message: "An error occurred while checking the review status. Please try again.",
          type: "error",
        });
      });
    }

    onMounted(() => {
      const storedNewArticle = sessionStorage.getItem("newArticle");
      const storedReviewStatus = sessionStorage.getItem("reviewStatus");
      const storedNewId = sessionStorage.getItem("newId");
      console.log(storedReviewStatus);
      if (storedNewArticle && storedReviewStatus === "pending" && storedNewId) {
        reviewStatus.value = "pending";
        newId.value = parseInt(storedNewId, 10);
        checkReviewStatus(newId.value);
        console.log('reviewStatus'+reviewStatus.value);
      }
      if (storedNewArticle && (storedReviewStatus === 'changing' || storedReviewStatus === 'modifyarticlelv3') && storedNewId) {
        reviewStatus.value = storedReviewStatus;
        newId.value = parseInt(storedNewId, 10);
        console.log('reviewStatus'+reviewStatus.value);
      }
    });

    watch(
      () => Ans.value,
      () => {
        sessionStorage.setItem("revise", 1);
      },
      {
        deep: true,
      }
    );

    function back() {
      if (sessionStorage.getItem("newArticle")) {
        sessionStorage.removeItem("newArticle");
      }
      if (sessionStorage.getItem("newQuestion")) {
        sessionStorage.removeItem("newQuestion");
      }
      if (sessionStorage.getItem("article")) {
        sessionStorage.removeItem("article");
      }
      sessionStorage.removeItem("reviewStatus");
      sessionStorage.removeItem("newId");
      router.push("/");
    }

    return {
      submitArticle,
      back,
      Ans,
      newId,
      reviewStatus,
    };
  },
};
</script>

<style scoped>
.articlesadd-wrap {
  display: block;
  width: 1100px;
  margin: 0 auto;
}
.add-title {
  display: flex;
  height: 126px;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #14279b;
  font-family: "Poppins-Bold";
  position: relative;
}
.add-back {
  position: absolute;
  left: 0;
  top: 22px;
  width: 93px;
  height: 35px;
  padding: 0 15px 0 38px;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #14279b;
  border-radius: 18px;
  border: 2px solid #111880;
  font-family: "Poppins-SemiBold";
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  /* background:url(../images/icon-add-back.png) no-repeat 12px center; */
  background-size: 19px 19px;
}
.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 175px;
  padding-bottom: 30px;
  position: relative;
}
.add-btn .submit-tips {
  bottom: 150px;
  height: 130px;
}
.add-save {
  position: absolute;
  left: 0;
  top: 22px;
  width: 93px;
  height: 35px;
  padding: 0 15px 0 38px;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #14279b;
  border-radius: 18px;
  border: 2px solid #111880;
  font-family: "Poppins-SemiBold";
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  /* background:url(../images/icon-add-save.png) no-repeat 12px center; */
  background-size: 19px 19px;
}
.add-back:hover,
.add-save:hover {
  color: #0e1792;
  border: 2px solid #0e1792;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
.add-step {
  display: block;
  width: 1100px;
  height: 45px;
  border-radius: 26px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.add-step ul {
  display: flex;
}
.add-step li {
  width: 33.33%;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  color: #14279b;
  font-family: "Poppins-Bold";
  text-align: center;
  /* background:url(../images/icon-step.png) no-repeat 88% center; */
  background-size: 24px 25px;
}
.add-step li.cur {
  color: #ffffff;
  border-radius: 0 23px 23px 0;
  background: #5473ff no-repeat 88% center;
  background-size: 24px 25px;
}
.add-step li.cur.angle {
  border-radius: 0;
}
.add-box {
  display: block;
  width: 100%;
  margin-top: 30px;
  /*padding: 45px;*/
  height: auto;
  border-radius: 30px;
  margin-bottom: 15px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.add-box.preview {
  padding: 0 32px 20px 32px;
}
.add-box.preview .articles-wrap {
  width: 100%;
}
.add-box.preview .articles-banner {
  width: 100%;
  background-color: #98ca9f;
}
.add-box h2 {
  display: block;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 24px;
  font-family: "Poppins-Bold";
  color: #14279b;
}
.add-item {
  /*width: 80%;*/
  /*border: 1px solid;*/
  display: block;
  margin: 25px;
  position: relative;
}
.add-item.flex {
  display: flex;
  /*width: 80%;*/
  justify-content: space-between;
}
.add-item.flex .w50 {
  width: 45%;
}
.add-label {
  display: block;
  font-size: 16px;
  font-family: "Poppins-Bold";
  margin-bottom: 15px;
}
.add-label-block {
  display: block;
}
.add-input {
  width: 100%;
  height: 45px;
  font-size: 15px;
  padding-top: 10px;
  background-color: #eff1f5;
  border: none;
  border-radius: 15px;
}
.add-input.w135 {
  width: 135px;
}
.add-input:hover,
.add-input:focus {
  background-color: #e5e9f2;
}
.add-textarea {
  width: 100%;
  font-size: 15px;
  padding-top: 10px;
  background-color: #eff1f5;
  border: none;
  border-radius: 15px;
  min-height: 100px;
}
.add-text {
  padding-left: 18px;
  font-size: 14px;
  color: #969696;
}
.form-btn {
  display: block;
  width: 180px;
  height: 50px;
  font-size: 20px;
  color: #fff !important;
  line-height: 50px;
  text-align: center;
  border: none;
  margin: 0 auto;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Poppins-Black";
  background-color: #5473ff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.form-btn:hover {
  background-color: #4261f0;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
.review-message {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #14279b;
    font-family: "Poppins-Bold";
    height: 200px; /* Adjust height as needed */
    text-align: center;
    margin-top: 30px;
  }
  
</style>
